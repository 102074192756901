.styled-table {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-top: 1rem;
    font-size: 14px;
    
    th, td {
      padding: 8px 12px;
      text-align: center;
    }
  
    th {
      background-color: var(--primary);
      font-weight: bold;
      text-transform: uppercase;
      &:hover {
        background-color: var(--primary);
        cursor: pointer;
        color: white;
      }
    }
  
    td {
      vertical-align: middle;
      cursor: pointer;
      &.green-pnl {
        color: green;
        font-weight: bold;
      }
  
      &.red-pnl {
        color: red;
        font-weight: bold;
      }
  
      &.hover-effect:hover {
        background-color: #b43333;
        &td{
            background-color: #b43333;

        }
        cursor: pointer;
      }
    }
    
    .pagination {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
  
      .page-item {
        cursor: pointer;
  
        &:hover {
          background-color: #ddd;
        }
      }
  
      .page-link {
        padding: 0.5rem 1rem;
      }
  
      .active {
        background-color: #007bff;
        color: white;
      }
    }
  }
  