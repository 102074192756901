.date-range-picker {
    position: relative;
    width: 100%;
  }
  
  .calendar-dropdown {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    padding: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    display: flex;
    flex-direction: column;
  }
  
  .calendar-section {
    margin-bottom: 10px;
  }
  
.calendar-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
}
.react-datepicker-popper {
    z-index: 100;
}