/* Styling for positive PnL */
.positive-pnl .custom-event-content {
    background-color: var(--green-pnl); /* Light green */
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Styling for negative PnL */
  .negative-pnl .custom-event-content {
    background-color: var(--red-pnl); 
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .fc-daygrid-event {
    margin: 0 !important; 
    padding: 0 !important; 
  }
  
  .fc-daygrid-event-dot {
    display: none;
  }
  
  .fc-daygrid-day-frame {
    height: 100%;
  }
  
  .fc-daygrid-day-top {
    padding: 0;
    margin: 0;
  }
  
  .custom-event-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.9em;
  }
  